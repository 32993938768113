import ReviewCard from "./ReviewCard/ReviewCard";
import "./ReviewCards.css";

const ReviewCards = () => {

  const reviewInfo = [
    { id: "re1", review: `"5-Star!! The chocolate ding dongs are the best! Moist and Delicious, perfect dessert for a bbq"`, signer: "Stephanie", app: "DoorDash" },
    { id: "re2", review: `"Brownie on a stick were da "bomb" packaging was nice too.  Would 100% get as gifts".`, signer: "Jeremiah", app: "Direct Order" },
    { id: "re3", review: `"Lemon cupcake -- loved it.  Super large too."`, signer: "Shirley", app: "UberEats" },
  ]

  return (
    <div className="review-wrapper" aria-label="Restaurant Reviews">
      <div className="review-title" role="heading" aria-level="2">
        What people are saying
      </div>
      <div className="review-icons-wrapper">
        {reviewInfo.map((data) => {
          return <ReviewCard key={data.id} reviewInfo={data} />
        })}
      </div>
    </div>
  );
};

export default ReviewCards;
