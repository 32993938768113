import HeroImg from "../../assets/mainItems/heroImg.png"
import Navbar from "../../components/ui/navbar/Navbar"

import "./Disclaimers.css"

const Disclaimers = () => {
    return <div aria-label="Disclaimers View" >
        <a href="#main-disclaimer-info" className="skip-to-main">Skip to main content</a>
        <Navbar />
        <div className="spacer"></div>
        <div className="disclaimers-main-wrapper" id="main-disclaimer-info">
            <img src={HeroImg} alt="main show food" className="disclaimers-image-style" />
            <h1 className="disclaimers-title" role="heading">Disclaimers</h1>
            <div role="list" >
                <div className="disclaimers-content-wrapper" role="listitem">
                    <div className="disclaimer-title">1. Refund Policy</div>
                    <div className="disclaimer-text">We do not accept returns and do not issue refunds for our products or for shipping due to the perishable nature of our excellent products. If you have a concern with your purchase please email: orders@debbiesdinners.com and let us know how we can improve.</div>
                    <div className="disclaimer-text">If you ordered through a delivery service like UberEats, Doordash, etc., please contact them for any refunds, cancellations or changes.</div>
                </div>
                <div className="disclaimers-content-wrapper" role="listitem">
                    <div className="disclaimer-title">2. Transferring Orders</div>
                    <div className="disclaimer-text">We are unable to transfer your order to alternate locations, we know it's exciting to order such great delights, please take your time in making selections and location choices when completing your order.</div>
                </div>
            </div>
        </div>
    </div>
}

export default Disclaimers
