import ReviewCards from "./ReviewCards/ReviewCards";

const Reviews = () => {
  return (
    <div>
      <ReviewCards />
    </div>
  );
};

export default Reviews;
