import { BiSolidStar } from "react-icons/bi"

import { Rating } from 'react-simple-star-rating'


import './StarReviews.css'

const StarReviews = (params) => {
    return <div className='star-review-wrapper' aria-label="Star reviews from UberEats and DoorDash apps">
        <div className="star-singular-review">
            <div className="star-singular-title" role="heading" aria-level="2">UberEats:</div>
            <Rating
                initialValue={4.8}
                readonly
                allowFraction
                fillColor='black'
                emptyColor='transparent'
                fillIcon={<BiSolidStar size={50} />}
                size={50}
            />
            <div className="star-singular-subtitle">4+ star overall rating</div>
        </div>
        <div className="star-singular-review">
            <div className="star-singular-title" role="heading" aria-level="2">DoorDash:</div>
            <Rating
                initialValue={4.8}
                readonly
                allowFraction
                fillColor='black'
                emptyColor='transparent'
                fillIcon={<BiSolidStar size={50} />}
                size={50}
            />
            <div className="star-singular-subtitle">4+ star overall rating</div>
        </div>

    </div>
}

export default StarReviews