import "./ReviewCard.css";

const ReviewCard = ({ reviewInfo }) => {
    return (
        <div className="review-card-wrapper">
            <div className="review-card-title">
                {reviewInfo.review}
            </div>
            <div className="signer-review-card">
                <div>
                    {reviewInfo.signer},
                </div>
                <div>
                    {reviewInfo.app} Customer
                </div>
            </div>
        </div>
    );
};

export default ReviewCard;
